import {css, keyframes} from 'styled-components';

export const ease = {
    sharp: 'cubic-bezier(.6,0,0,1)',
};

export const pageTransitions = css`
    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
        transition: opacity 500ms ${ease.sharp};
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 500ms ${ease.sharp};
        pointer-event: none;
    }
`;

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const fadeSlideIn = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 48px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const scaleIn = keyframes`
    0% {
        opacity: 0;
        transform: scale3d(0.25,0.25,1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1,1,1);
    }
`;
