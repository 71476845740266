import React, {useCallback, useState} from 'react';
import styled from 'styled-components';

import copyIcon from 'images/icons/copy.svg';
import facebookIcon from 'images/icons/facebook.svg';
import whatsappIcon from 'images/icons/whatsapp.svg';
import characters from './images/characters.svg';

import Text from 'components/Text/Text';
import {Button, Modal} from 'components';

const Characters = styled.div`
    height: 240px;
    margin: -2rem 0 0 -2rem;
    background: url(${characters}) no-repeat top left;
`;

const Heading = styled(Text)`
    display: block;
    margin-bottom: 1rem;
`;

const Description = styled(Text)`
    display: block;
    margin-bottom: 1.5rem;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    margin: -0.5rem -0.5rem 0;

    > * {
        margin: 0.5rem;
    }
`;

const ShareButton = styled(Button)`
    width: 68px;
    height: 68px;
`;

const WhatsappButton = styled(ShareButton)`
    background-image: ${`url(${whatsappIcon})`};
`;

const FacebookButton = styled(ShareButton)`
    background-image: ${`url(${facebookIcon})`};
`;

const CopyButton = styled(ShareButton)`
    background-image: ${`url(${copyIcon})`};
`;

const Copied = styled(Text)`
    position: absolute;
    left: 0;
    right: 0;
`;

export default function Share({onClose}) {
    const sharingUrl = window.location.host;

    const [copied, setCopied] = useState();

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(sharingUrl);
        setCopied(true);
    }, [sharingUrl]);

    return (
        <Modal onClose={onClose} trackingLabel="Share">
            <Characters />
            <Heading tag="h1" type={Text.types.h3}>
                Send to a friend.
            </Heading>
            <Description tag="p" type={Text.types.body}>
                Share this experience to WhatsApp or Facebook, or copy a link to
                your clipboard.
            </Description>
            <Buttons>
                <WhatsappButton
                    external={true}
                    round
                    href={`https://wa.me/?text=${encodeURIComponent(
                        sharingUrl
                    )}`}
                    trackingLabel="Share: Whatsapp"
                />
                <FacebookButton
                    external={true}
                    round
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        sharingUrl
                    )}`}
                    trackingLabel="Share: Facebook"
                />
                <CopyButton
                    round
                    onClick={handleCopy}
                    trackingLabel="Share: Copy To Clipboard"
                />
            </Buttons>
            {copied && (
                <Copied type={Text.types.small}>
                    Link copied to clipboard
                </Copied>
            )}
        </Modal>
    );
}
