import styled from 'styled-components';

import * as breakpoints from 'theme/breakpoints';
import * as colors from 'theme/colors';

export default styled.div`
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: ${colors.white};
    background: linear-gradient(
        ${props => props.theme.secondary} 0%,
        ${props => props.theme.background} 100%
    );

    ${breakpoints.portrait} {
        border-radius: 0 0 30px 30px;
    }

    ${breakpoints.landscape} {
        border-radius: 0 30px 30px 0;
    }
`;
