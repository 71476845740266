import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

import {
    Animation,
    Button,
    CTA,
    Emoji,
    Floater,
    Footer,
    Page,
    Text,
} from 'components';

import {ease} from 'theme/transitions';
import animations from 'config/animations';
import * as breakpoints from 'theme/breakpoints';
import * as queryconfig from 'config/queryparams';
import * as transitions from 'theme/transitions';

import {getEmojiOptions} from 'selectors/emoji';
import {paths} from 'App';

const positions = [
    {
        x: 70.88948787061994,
        y: 5.293276108726753,
    },
    {
        x: 83.01886792452831,
        y: 25.17882689556509,
    },
    {
        x: 88.94878706199461,
        y: 62.23175965665236,
    },
    {
        x: 81.94070080862534,
        y: 79.8283261802575,
    },
    {
        x: 56.33423180592992,
        y: 94.13447782546494,
    },
    {
        x: 22.10242587601078,
        y: 83.2618025751073,
    },
    {
        x: 9.973045822102426,
        y: 55.79399141630901,
    },
    {
        x: 18.059299191374663,
        y: 25.894134477825464,
    },
    {
        x: 34.23180592991914,
        y: 9.72818311874106,
    },
];

const Container = styled(Page)`
    padding: 6rem 0 0;
`;

const Content = styled.div`
    display: flex;
    flex: 1 1 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;

    ${breakpoints.smallLandscape} {
        margin-bottom: 3rem;
    }
`;

const Char = styled(Animation)`
    width: 100%;
    height: 320px;
    max-height: 75vh;
    margin: -84px 0;
    animation: ${transitions.scaleIn} 0.5s ${ease.sharp};
`;

const Description = styled(Text)`
    max-width: 200px;
    margin-bottom: 2rem;

    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 0.5s both;

    ${breakpoints.smallPhone} {
        max-width: 160px;
    }
`;

const Buttons = styled.div`
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100%;
    max-width: 480px;
    max-height: 720px;
`;

const EmojiButton = styled(Button)`
    animation: ${transitions.scaleIn} 1s ${ease.sharp};
    animation-fill-mode: backwards;

    ${breakpoints.smallLandscape} {
        font-size: 20px;
        img {
            width: 0.8em;
            height: 0.8em;
        }
    }
`;

export default function Character() {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const age = params.get(queryconfig.age);
    const [emojiOptions, setEmojiOptions] = useState([]);
    const isMixItUp = !!useRouteMatch(paths.mixItUp());

    const initEmoji = useCallback(() => {
        setEmojiOptions(getEmojiOptions(age));
    }, [age]);

    const getEmojiLink = useCallback(
        (emoji) => {
            const params = new URLSearchParams(search);
            params.append(queryconfig.emoji, emoji.id);

            return {
                pathname: paths.playTrack('track'),
                search: params.toString(),
            };
        },
        [search]
    );

    useEffect(() => {
        initEmoji();
    }, [initEmoji]);

    // Don't re-render unless absolutely necessary, as this causes animations to glitch...
    return useMemo(
        () => (
            <Container
                trackingLabel={isMixItUp ? 'Mix It Up' : 'Select Emoji To Play'}
            >
                <CTA />
                <Content>
                    <Char animation={animations.shrug} scale={null} />
                    <Description tag="h1" type={Text.types.h4}>
                        {isMixItUp ? (
                            <>Pick an emoji to mix it up!</>
                        ) : (
                            <>Pick an emoji to start the party!</>
                        )}
                    </Description>
                    <Buttons>
                        {positions.map(({x, y}, i) => {
                            const emoji = emojiOptions[i];
                            if (!emoji) return null;

                            return emoji ? (
                                <Floater
                                    key={emoji.id}
                                    style={{
                                        position: 'absolute',
                                        top: `${y}%`,
                                        left: `${x}%`,
                                    }}
                                >
                                    <EmojiButton
                                        aria-label={emoji.symbol}
                                        elevated
                                        href={getEmojiLink(emoji)}
                                        style={{
                                            animationDelay: `${
                                                i * 100 + 1000
                                            }ms`,
                                            position: 'absolute',
                                        }}
                                        round
                                        trackingLabel="Emoji"
                                        trackingVars={{emoji: emoji.id}}
                                    >
                                        <Emoji aria-hidden emoji={emoji.id} />
                                    </EmojiButton>
                                </Floater>
                            ) : null;
                        })}
                    </Buttons>
                </Content>
                <Footer />
            </Container>
        ),
        [emojiOptions, getEmojiLink, isMixItUp]
    );
}
