import React from 'react';
import styled, {css} from 'styled-components';

const distance = () => Math.random() * 10 - 5;
const duration = () => Math.random() * 2 + 1;
const offset = d => -(Math.random() * d) / 2;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes floatX {
        0% {
            transform: translateX(${distance()}px);
        }
        100% {
            transform: translateX(${distance()}px);
        }
    }

    @keyframes floatY {
        0% {
            transform: translateY(${distance()}px);
        }
        100% {
            transform: translateY(${distance()}px);
        }
    }

    ${() => {
        const d1 = duration();
        const o1 = offset(d1);

        const d2 = duration();
        const o2 = offset(d2);

        return css`
            animation: floatX ${d1}s ease-in-out infinite alternate;
            animation-delay: ${o1}s;

            > * {
                display: flex;
                align-items: center;
                justify-content: center;
                animation: floatY ${d2}s ease-in-out infinite alternate;
                animation-delay: ${o2}s;
            }
        `;
    }}
`;

export default function Floater({children, ...otherProps}) {
    return (
        <Container {...otherProps}>
            <div>{children}</div>
        </Container>
    );
}
