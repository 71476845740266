import {css} from 'styled-components';
import * as breakpoints from 'theme/breakpoints';

export const jackfruit = css`
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -2.24px;

    ${breakpoints.tablet} {
        font-size: 72px;
    }
    ${breakpoints.smallPhone} {
        font-size: 41px;
        line-height: 49px;
    }
`;

export const watermelon = css`
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1.92px;

    ${breakpoints.tablet} {
        font-size: 52px;
    }
    ${breakpoints.smallPhone} {
        font-size: 28px;
        line-height: 38px;
    }
`;

export const pineapple = css`
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.6px;

    ${breakpoints.tablet} {
        font-size: 44px;
    }
    ${breakpoints.smallPhone} {
        font-size: 28px;
        line-height: 38px;
    }
`;

export const papaya = css`
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -1.36px;

    ${breakpoints.tablet} {
        font-size: 38px;
    }
    ${breakpoints.smallPhone} {
        font-size: 24px;
        line-height: 30px;
    }
`;

export const mango = css`
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1.12px;

    ${breakpoints.tablet} {
        font-size: 32px;
    }
    ${breakpoints.smallPhone} {
        font-size: 20px;
        line-height: 23px;
    }
`;

export const pomegranate = css`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;

    ${breakpoints.tablet} {
        font-size: 24px;
    }
    ${breakpoints.smallPhone} {
        font-size: 18px;
        line-height: 22px;
    }
`;

export const orangeBook = css`
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0;

    ${breakpoints.smallPhone} {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const orangeBold =
    orangeBook +
    css`
        font-weight: bold;
    `;

export const appleBook = css`
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
`;

export const appleBold =
    appleBook +
    css`
        font-weight: bold;
    `;

export const blueberryBook = css`
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0;
`;

export const blueberryBold =
    blueberryBook +
    css`
        font-weight: bold;
    `;

export const raspberry = css`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
`;

export const strawberry = css`
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    ${breakpoints.smallPhone} {
        font-size: 13px;
        line-height: 16px;
    }
`;

export const cranberry = css`
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;
