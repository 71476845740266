import React from 'react';
import styled, {css} from 'styled-components';

import * as breakpoints from 'theme/breakpoints';

import volume from 'images/icons/volume.svg';
import volumeOff from 'images/icons/volume-off.svg';

import {Button} from 'components';

const StyledButton = styled(Button)`
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    background-image: url(${(props) => (props.muted ? volumeOff : volume)});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border: none;
    ${(props) =>
        !props.onlyMobile &&
        css`
            display: none;
        `}

    ${breakpoints.landscape} {
        display: inline;
        margin-right: 17px;
        ${(props) =>
            props.onlyMobile &&
            css`
                display: none;
            `}
    }
`;

export default function MuteButton({muted, onlyMobile, ...otherProps}) {
    return (
        <StyledButton
            aria-label={muted ? 'Unmute' : 'Mute'}
            muted={muted}
            round
            onlyMobile={onlyMobile}
            {...otherProps}
        />
    );
}
