import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {Animation, Button, CTA, Floater, Page, Text, Footer} from 'components';

import * as ages from 'config/ages';
import animations from 'config/animations';
import * as breakpoints from 'theme/breakpoints';
import * as queryconfig from 'config/queryparams';
import * as transitions from 'theme/transitions';
import {ease} from 'theme/transitions';
import {themes} from 'theme/colors';

import character2 from './images/character2.svg';
import character3 from './images/character3.svg';

import {paths} from 'App';

const Container = styled(Page)`
    position: relative;
    overflow-x: hidden;
    ${breakpoints.smallLandscape} {
        flex-direction: row;
    }
`;

const ContentsLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${breakpoints.smallLandscape} {
        flex-direction: column-reverse;
        padding-top: 20px;
        max-width: 44%;
        margin: 0 3%;
    }
`;

const ContentsRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${breakpoints.smallLandscape} {
        padding-top: 20px;
        max-width: 44%;
        margin: 0 3%;
    }
`;

const Character1 = styled(Animation)`
    width: 300px;
    height: 300px;
    margin: -4.5rem 0 -4rem;

    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 0.5s both;

    ${breakpoints.tablet} {
        width: 600px;
        height: 600px;
        margin: -9rem 0 -8rem;
    }
    ${breakpoints.smallLandscape} {
        display: none;
    }
`;

const Character2 = styled.div`
    @keyframes enter2 {
        0% {
            transform: translate3d(-182px, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    top: 5%;
    left: 0;
    width: 91px;
    height: 152px;
    position: absolute;
    background: url(${character2}) no-repeat left;
    background-size: contain;

    animation: enter2 1s ${ease.sharp} 2.5s both;

    ${breakpoints.tablet} {
        width: 182px;
        height: 305px;
    }
`;

const Character3 = styled.div`
    @keyframes enter3 {
        0% {
            transform: translate3d(206px, 0, 0);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    top: 20%;
    right: 0;
    width: 103px;
    height: 163px;
    position: absolute;
    background: url(${character3}) no-repeat right;
    background-size: contain;

    animation: enter3 1s ${ease.sharp} 3s both;

    ${breakpoints.tablet} {
        width: 206px;
        height: 327px;
    }
`;

const Heading = styled(Text)`
    margin-bottom: 2rem;
    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 1s both;
    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const Subheading = styled(Text)`
    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 1s both;
    margin-bottom: 0.5rem;
`;

const Ages = styled.div`
    display: flex;
    margin: -0.5rem -0.5rem 1.5rem;

    > * {
        margin: 0.5rem;
    }
    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const AgeButton = styled(Button)`
    display: flex;
    flex-direction: column;
    font-size: 42px;

    animation: ${transitions.scaleIn} 1s ${ease.sharp} 2s both;

    ${breakpoints.smallPhone} {
        font-size: 37px;
    }
`;

const SkipButton = styled(Button)`
    animation: ${transitions.fadeIn} 1s ${ease.sharp} 2s both;
`;

export default function SelectAge() {
    const getAgeUrl = (age) =>
        `${paths.selectEmoji()}?${queryconfig.age}=${age}`;

    const options = [
        {
            id: ages.younger,
            href: getAgeUrl(ages.younger),
            label: 'Under 6',
            trackingLabel: 'Age: Under 6',
        },
        {
            id: ages.older,
            href: getAgeUrl(ages.older),
            label: '6 or over',
            trackingLabel: 'Age: 6 or over',
        },
    ];

    return (
        <ThemeProvider theme={themes.blue}>
            <Container trackingLabel="Select Age">
                <ContentsLeft>
                    <CTA label="START LISTENING ON" />
                    <Character1 animation={animations.shuffle} />
                    <Character2 />
                    <Character3 />
                    <Subheading tag="p" type={Text.types.body}>
                        Spotify Kids has music for children of all ages.
                    </Subheading>
                    <Heading tag="h1" type={Text.types.h3}>
                        How old is your little one?
                    </Heading>
                </ContentsLeft>
                <ContentsRight>
                    <Ages>
                        {options.map(
                            ({id, href, label, trackingLabel}, index) => (
                                <Floater key={id}>
                                    <AgeButton
                                        href={href}
                                        style={{
                                            animationDelay: `${
                                                index * 100 + 1250
                                            }ms`,
                                        }}
                                        round
                                        trackingLabel={trackingLabel}
                                    >
                                        <Text type={Text.types.h5}>
                                            {label}
                                        </Text>
                                    </AgeButton>
                                </Floater>
                            )
                        )}
                    </Ages>
                    <SkipButton
                        small
                        dark
                        href={paths.selectEmoji()}
                        trackingLabel="Age: None"
                    >
                        Show me everything
                    </SkipButton>
                </ContentsRight>
                <Footer />
            </Container>
        </ThemeProvider>
    );
}
