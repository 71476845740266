import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 2rem;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default function Drawer({...otherProps}) {
    return <Container {...otherProps} />;
}
