import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import * as colors from 'theme/colors';
import * as breakpoints from 'theme/breakpoints';
import * as typography from 'theme/typography';
import * as queryconfig from 'config/queryparams';

import {track} from 'util/analytics';
import {useQueryParams} from 'util/url';

const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: inherit;
    background: none no-repeat center;
    border: none;
    font: inherit;
    cursor: pointer;
    text-decoration: inherit;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
        color 0.2s ease-in-out, transform 0.2s ease-in-out;
    user-select: none;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${colors.yellow};
    }

    &::-moz-focus-inner {
        border: 0;
    }

    ${(props) =>
        props.$large &&
        css`
            ${typography.strawberry}
            padding: 14px 24px;
            color: ${colors.black};
            background-color: ${props.theme.accent};
            border-radius: 1000px;
            line-height: 1;

            @media (hover: hover) {
                &:hover,
                :focus {
                    background-color: ${props.theme.highlight};
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        `}

    ${(props) =>
        props.$small &&
        css`
            ${typography.raspberry}
            padding: 8px 14px 6px;
            border-radius: 1000px;
            line-height: 1;

            @media (hover: hover) {
                &:hover,
                :focus {
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        `}
      
  ${(props) =>
        props.$dark &&
        css`
            color: ${colors.white};
            background-color: rgba(0, 0, 0, 0.4);

            @media (hover: hover) {
                &:hover,
                :focus {
                    background-color: ${props.theme.foregroundColor};
                }
            }
        `}
      
  ${(props) =>
        props.$round &&
        css`
            ${typography.mango}
            display: flex;
            align-items: center;
            width: 2.75em;
            height: 2.75em;
            color: white;
            background-color: ${(props) => props.theme.secondary};
            border: 3px solid ${(props) => props.theme.primary};
            border-radius: 100px;
            line-height: 1;

            @media (hover: hover) {
                &:hover,
                :focus {
                    color: ${props.theme.background};
                    border-color: ${props.theme.highlight};
                    background-color: ${props.theme.highlight};
                    transform: scale3d(1.1, 1.1, 1);
                }
            }

            ${(props) =>
                props.disabled &&
                css`
                    cursor: unset;

                    @media (hover: hover) {
                        &:hover,
                        :focus {
                            color: white;
                            background-color: ${(props) =>
                                props.theme.secondary};
                            border: unset;
                            transform: unset;
                        }
                    }
                `}

            ${(props) =>
                props.$elevated &&
                css`
                    box-shadow: 0px 104.819px 83.8554px rgba(0, 0, 0, 0.23),
                        0px 43.791px 35.0328px rgba(0, 0, 0, 0.165337),
                        0px 23.4128px 18.7302px rgba(0, 0, 0, 0.137105),
                        0px 13.125px 10.5px rgba(0, 0, 0, 0.115);
                `}
        `}

        ${(props) =>
        props.$external &&
        css`
            ${breakpoints.smallPhone} {
                $:hover {
                    color: inherit;
                    background-color: inherit;
                    transform: unset;
                    border: inherit;
                }
            }
        `}
`;

const InternalLink = styled(Container).attrs({as: Link})``;
const ExternalLink = styled(Container).attrs({as: 'a'})``;

export default function Button({
    disabled,
    dark,
    elevated,
    href,
    large,
    onClick,
    round,
    external,
    small,
    trackingLabel,
    trackingVars,
    ...otherProps
}) {
    const params = useQueryParams();
    const debug = params.get(queryconfig.debug);

    const isInternalLink =
        href && (typeof href === 'object' || !href.includes('://'));

    const Component = href
        ? isInternalLink
            ? InternalLink
            : ExternalLink
        : Container;

    if (isInternalLink && debug !== null) {
        const params = new URLSearchParams(
            typeof href === 'object' ? href.search : href.split('?')[1]
        );

        params.set(queryconfig.debug, true);
        const search = params.toString();

        if (typeof href === 'object') href.search = search;
        else href = `${href.split(`?`)[0]}?${search}`;
    }

    const handleClick = useCallback(() => {
        trackingLabel && track(`Button: ${trackingLabel}`, trackingVars);
        onClick && onClick();
    }, [onClick, trackingLabel, trackingVars]);

    return (
        <Component
            $dark={dark}
            $elevated={elevated}
            $large={large}
            $round={round}
            $small={small}
            $external={external}
            href={(!isInternalLink && href) || null}
            onClick={handleClick}
            disabled={disabled}
            target={(href && !isInternalLink && '_blank') || null}
            to={(isInternalLink && href) || null}
            {...otherProps}
        />
    );
}
