import React from 'react';
import styled, {css} from 'styled-components';

import * as colors from 'theme/colors';
import * as transitions from 'theme/transitions';

import {Button} from 'components';

const Container = styled.footer`
    position: absolute;
    bottom: 4px;
    z-index: 2;
    font-size: 10px;
    padding-top: 1rem;
    color: rgba(256, 256, 256, 0.5);
    text-transform: uppercase;
    width: 100%;

    ${(props) =>
        props.isSummary &&
        css`
            position: relative;
            animation: unset;
        `}
    ${(props) =>
        props.isHome &&
        css`
            position: relative;
            animation: ${transitions.fadeIn} 1s ${transitions.ease.sharp} 2.6s
                both;
        `}
`;

const Nav = styled.nav`
    padding: 0.6rem;
    background: transparent;
`;

const List = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0 -0.5rem;
    padding: 0;
`;

const ListItem = styled.li`
    padding: 0;
`;

const Link = styled(Button)`
    padding: 0.2rem 0.3rem;
    border-radius: 0.25rem;
    text-decoration: underline;

    &:hover {
        color: ${colors.green};
    }
`;

const ALink = styled.a`
    padding: 0.2rem 0.3rem;
    border-radius: 0.25rem;
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.5);

    &:hover {
        color: ${colors.green};
    }
`;

const Copyright = styled.div`
    padding: 0.2rem 0.3rem;
`;

const links = [
    {
        label: 'Legal',
        url: 'https://www.spotify.com/legal/',
    },
    {
        label: 'Privacy',
        url: 'https://www.spotify.com/legal/privacy-policy/',
    },
    {
        label: 'Privacy Center',
        url: 'https://www.spotify.com/privacy/',
    },
    {
        label: 'Cookies',
        url: window.__cookie_url__ || 'https://www.spotify.com/legal/cookies-policy/',
    },
    {
        label: 'Additional CA Privacy Disclosures',
        url: 'https://www.spotify.com/legal/California-privacy-disclosure',
    },
];

export default function Footer({isSummary, isHome, ...otherProps}) {
    return (
        <Container isSummary={isSummary} isHome={isHome} {...otherProps}>
            <Nav>
                <List>
                    {links.map(({label, url}) => (
                        <ListItem key={url}>
                            {label === 'Cookies' && <ALink href={url}>
                                {label}
                            </ALink>}
                            {label !== 'Cookies' && <Link href={url} text>
                                {label}
                            </Link>}
                        </ListItem>
                    ))}
                    <ListItem>
                        <Copyright>
                            &copy;{new Date().getFullYear()} Spotify AB
                        </Copyright>
                    </ListItem>
                </List>
            </Nav>
        </Container>
    );
}
