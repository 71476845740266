import React from 'react';
import styled from 'styled-components';

import {Button} from 'components';
import {isAndroid, isIos} from 'util/device';

const Container = styled(Button)`
    width: 100%;
    max-width: 300px;
`;

export default function DownloadButton({...otherProps}) {
    const href = isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.spotify.kids'
        : isIos
        ? 'https://apps.apple.com/app/spotify-kids/id1470209570'
        : 'https://www.spotify.com/kids/';

    const label =
        isAndroid || isIos ? 'Download Spotify Kids' : 'Find out more';

    return (
        <Container large external={true} href={href} {...otherProps}>
            {label}
        </Container>
    );
}
