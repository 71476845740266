import React from 'react';
import styled, {ThemeConsumer} from 'styled-components';
import {Button} from 'components';
import Text from 'components/Text/Text';
import * as breakpoints from 'theme/breakpoints';
import {paths} from 'App';

const Logo = () => (
    <ThemeConsumer>
        {(theme) => (
            <svg
                fill={theme.accent}
                height="26"
                width="117"
                viewBox="0 0 117 26"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Spotify Kids</title>
                <path d="M94.9812 9.98143H92.5247C92.4527 9.98143 92.3832 10.0113 92.3348 10.0633L88.9695 13.6344V6.77953C88.9695 6.69763 88.9027 6.63003 88.8189 6.63003H86.7957C86.7132 6.63003 86.6451 6.69633 86.6451 6.77953V18.889C86.6451 18.9709 86.7119 19.0385 86.7957 19.0385H88.8189C88.9014 19.0385 88.9695 18.9722 88.9695 18.889V16.445L90.0367 15.3426L92.5561 18.928C92.6045 18.9969 92.6844 19.0385 92.7695 19.0385H95.0899C95.1947 19.0385 95.2575 18.9215 95.1973 18.8357L91.6198 13.7137L95.0755 10.2011C95.1541 10.1192 95.0965 9.98143 94.9812 9.98143Z" />
                <path d="M106.554 6.77953V11.0799C105.766 10.218 104.828 9.79813 103.697 9.79813C101.587 9.79813 99.453 11.4101 99.453 14.4924V14.5262C99.453 15.925 99.9074 17.1236 100.766 17.992C101.538 18.7733 102.606 19.2205 103.698 19.2205C104.845 19.2205 105.784 18.7772 106.556 17.8672V18.889C106.556 18.9709 106.622 19.0385 106.706 19.0385H108.729C108.812 19.0385 108.88 18.9722 108.88 18.889V6.77953C108.88 6.69763 108.813 6.63003 108.729 6.63003H106.706C106.622 6.63003 106.554 6.69763 106.554 6.77953ZM106.588 14.4937V14.5275C106.588 15.8314 105.689 17.1808 104.183 17.1808C102.821 17.1808 101.794 16.0394 101.794 14.5275V14.4937C101.794 12.9311 102.776 11.8404 104.183 11.8404C105.554 11.8391 106.588 12.9805 106.588 14.4937Z" />
                <path d="M114.156 13.5707L113.982 13.5148C113.102 13.2353 112.272 12.9714 112.272 12.4852V12.4514C112.272 12.0016 112.688 11.7221 113.357 11.7221C114.031 11.7221 114.9 12.0107 115.742 12.5138C115.784 12.5385 115.835 12.5463 115.882 12.5333C115.929 12.5203 115.969 12.4891 115.993 12.4475L116.766 11.0734C116.811 10.9915 116.787 10.8862 116.707 10.8342C115.738 10.2063 114.504 9.83062 113.407 9.83062C111.469 9.83062 110.115 10.9902 110.115 12.6503V12.6841C110.115 14.4482 111.595 15.0111 112.979 15.3985L113.187 15.4609C114.076 15.7287 114.843 15.9588 114.843 16.4827V16.5165C114.843 17.0144 114.388 17.3121 113.624 17.3121C112.731 17.3121 111.745 16.9468 110.849 16.2838C110.81 16.2539 110.759 16.2422 110.709 16.25C110.661 16.2578 110.616 16.2864 110.589 16.328L109.721 17.6371C109.668 17.7151 109.687 17.8217 109.761 17.8789C110.848 18.72 112.237 19.2036 113.574 19.2036C115.688 19.2036 117 18.0908 117 16.3007V16.2682C117 14.4521 115.148 13.8788 114.156 13.5707Z" />
                <path d="M97.2624 5.98911C96.4596 5.98911 95.8088 6.63391 95.8088 7.42951C95.8088 8.22641 96.461 8.87121 97.2624 8.87121C98.0651 8.87121 98.7145 8.22641 98.7145 7.42951C98.7145 6.63391 98.0651 5.98911 97.2624 5.98911Z" />
                <path d="M96.2501 19.0385H98.2732C98.3557 19.0385 98.4238 18.9722 98.4238 18.889V10.1309C98.4238 10.049 98.357 9.9814 98.2732 9.9814H96.2501C96.1676 9.9814 96.0995 10.0477 96.0995 10.1309V18.8903C96.1008 18.9722 96.1676 19.0385 96.2501 19.0385Z" />
                <path d="M13.0947 0C5.8625 0 0 5.8201 0 13C0 20.1799 5.8625 26 13.0947 26C20.3269 26 26.1894 20.1799 26.1894 13C26.1894 5.8201 20.3269 0 13.0947 0ZM19.1 18.7512C18.8643 19.1347 18.3627 19.2543 17.9777 19.0203C14.9031 17.1548 11.0323 16.7336 6.47403 17.7671C6.03536 17.8672 5.59668 17.5942 5.49716 17.1574C5.39633 16.7219 5.67132 16.2864 6.1113 16.1876C11.0991 15.0553 15.3784 15.5428 18.8302 17.6371C19.2139 17.8698 19.3357 18.369 19.1 18.7512ZM20.7027 15.2113C20.4068 15.6884 19.7783 15.8379 19.299 15.5454C15.7804 13.3978 10.4142 12.7764 6.25011 14.0309C5.71061 14.1934 5.13968 13.8905 4.97599 13.3562C4.81362 12.8206 5.11742 12.2551 5.65692 12.0926C10.4129 10.66 16.3252 11.3542 20.3675 13.8203C20.8468 14.1102 20.9974 14.7342 20.7027 15.2113ZM20.8402 11.5245C16.6198 9.0363 9.65735 8.8075 5.6268 10.0217C4.97992 10.2167 4.29507 9.854 4.09996 9.2118C3.90354 8.5696 4.26888 7.8897 4.91576 7.6947C9.54081 6.3011 17.23 6.5702 22.0895 9.4341C22.6722 9.7773 22.8621 10.5235 22.5177 11.1007C22.1733 11.6779 21.4216 11.8677 20.8402 11.5245Z" />
                <path d="M35.6269 12.0016C33.3654 11.466 32.9634 11.0903 32.9634 10.3012C32.9634 9.555 33.6705 9.0532 34.722 9.0532C35.7421 9.0532 36.753 9.4341 37.8124 10.2193C37.8438 10.2427 37.8844 10.2531 37.9237 10.2466C37.963 10.2401 37.9983 10.2193 38.0219 10.1868L39.1258 8.6424C39.1716 8.5787 39.1585 8.4916 39.097 8.4422C37.8359 7.4373 36.4152 6.9485 34.7548 6.9485C32.3139 6.9485 30.609 8.4032 30.609 10.4832C30.609 12.7153 32.0795 13.5044 34.6212 14.1154C36.7844 14.6107 37.1498 15.0241 37.1498 15.7651C37.1498 16.5867 36.4112 17.0963 35.2235 17.0963C33.9036 17.0963 32.8272 16.6543 31.6238 15.6195C31.5937 15.5935 31.5531 15.5818 31.5151 15.5844C31.4758 15.5883 31.4392 15.6065 31.413 15.6364L30.1755 17.0989C30.1232 17.16 30.1297 17.2497 30.1899 17.303C31.5911 18.5445 33.3143 19.201 35.1738 19.201C37.8045 19.201 39.5042 17.7736 39.5042 15.5649C39.5042 13.6981 38.3807 12.6659 35.6269 12.0016Z" />
                <path d="M45.4557 9.7877C44.3152 9.7877 43.3802 10.2336 42.609 11.1475V10.1192C42.609 10.0386 42.5422 9.9723 42.461 9.9723H40.4365C40.3554 9.9723 40.2886 10.0386 40.2886 10.1192V21.5449C40.2886 21.6255 40.3554 21.6918 40.4365 21.6918H42.461C42.5422 21.6918 42.609 21.6255 42.609 21.5449V17.9387C43.3802 18.798 44.3152 19.2179 45.4557 19.2179C47.5745 19.2179 49.7194 17.5994 49.7194 14.5041C49.7194 11.4075 47.5745 9.7877 45.4557 9.7877ZM47.365 14.5028C47.365 16.0784 46.3868 17.1782 44.987 17.1782C43.6028 17.1782 42.5579 16.0277 42.5579 14.5028C42.5579 12.9766 43.6015 11.8274 44.987 11.8274C46.3645 11.8274 47.365 12.9519 47.365 14.5028Z" />
                <path d="M55.2166 9.7877C52.4876 9.7877 50.3506 11.8729 50.3506 14.5366C50.3506 17.1704 52.4732 19.2348 55.1825 19.2348C57.9206 19.2348 60.0642 17.1561 60.0642 14.5028C60.0655 11.8586 57.9363 9.7877 55.2166 9.7877ZM55.2166 17.1951C53.7657 17.1951 52.671 16.0368 52.671 14.5028C52.671 12.961 53.7277 11.843 55.1825 11.843C56.6426 11.843 57.7452 13 57.7452 14.5366C57.7452 16.0771 56.6819 17.1951 55.2166 17.1951Z" />
                <path d="M65.8914 9.97102H63.664V7.71032C63.664 7.62972 63.5985 7.56342 63.516 7.56342H61.4929C61.4104 7.56342 61.3449 7.62972 61.3449 7.71032V9.97102H60.372C60.2908 9.97102 60.224 10.0373 60.224 10.1179V11.8456C60.224 11.9262 60.2895 11.9925 60.372 11.9925H61.3449V16.4619C61.3449 18.2676 62.2498 19.1841 64.0359 19.1841C64.7626 19.1841 65.365 19.0359 65.932 18.7161C65.9778 18.6901 66.0066 18.6407 66.0066 18.5887V16.9442C66.0066 16.8935 65.9805 16.8454 65.9359 16.8194C65.8914 16.7921 65.8364 16.7908 65.7919 16.8129C65.4017 17.0079 65.0245 17.0976 64.6029 17.0976C63.9534 17.0976 63.664 16.8051 63.664 16.1486V11.9938H65.8914C65.9726 11.9938 66.0394 11.9275 66.0394 11.8469V10.1192C66.0394 10.0373 65.9739 9.97102 65.8914 9.97102Z" />
                <path d="M73.6539 9.98011V9.70191C73.6539 8.88551 73.9695 8.52021 74.6766 8.52021C75.0983 8.52021 75.4374 8.60341 75.8172 8.72951C75.8643 8.74381 75.9128 8.73731 75.9507 8.70871C75.99 8.68141 76.0123 8.63591 76.0123 8.58911V6.89651C76.0123 6.83151 75.9704 6.77431 75.9075 6.75481C75.5068 6.63651 74.9935 6.51431 74.2248 6.51431C72.3549 6.51431 71.3663 7.55951 71.3663 9.53681V9.96191H70.3933C70.3121 9.96191 70.2454 10.0282 70.2454 10.1088V11.8443C70.2454 11.9249 70.3121 11.9912 70.3933 11.9912H71.3663V18.8851C71.3663 18.9657 71.4317 19.032 71.5142 19.032H73.5387C73.6199 19.032 73.6867 18.9657 73.6867 18.8851V11.9938H75.5762L78.4715 18.8864C78.1428 19.6105 77.8193 19.7548 77.3781 19.7548C77.0219 19.7548 76.6461 19.6495 76.2624 19.4402C76.2257 19.4207 76.1838 19.4168 76.1445 19.4298C76.1066 19.4428 76.0738 19.4714 76.0568 19.5091L75.3706 21.0041C75.3379 21.0743 75.3654 21.1575 75.4348 21.1939C76.1511 21.5787 76.798 21.7438 77.5967 21.7438C79.0922 21.7438 79.9184 21.0522 80.6465 19.1932L84.1585 10.1829C84.1755 10.1374 84.1703 10.0867 84.1428 10.0464C84.1153 10.0061 84.0695 9.98271 84.021 9.98271H81.9141C81.8512 9.98271 81.7949 10.023 81.774 10.0815L79.6146 16.2032L77.2497 10.075C77.2275 10.0178 77.1725 9.98011 77.1109 9.98011H73.6539Z" />
                <path d="M69.1546 9.97101H67.1302C67.049 9.97101 66.9822 10.0373 66.9822 10.1179V18.8864C66.9822 18.967 67.049 19.0333 67.1302 19.0333H69.1546C69.2358 19.0333 69.3026 18.967 69.3026 18.8864V10.1192C69.3026 10.0373 69.2358 9.97101 69.1546 9.97101Z" />
                <path d="M68.1515 5.97871C67.3501 5.97871 66.6993 6.62351 66.6993 7.41911C66.6993 8.21601 67.3501 8.86082 68.1515 8.86082C68.9529 8.86082 69.6037 8.21601 69.6037 7.41911C69.6037 6.62351 68.9542 5.97871 68.1515 5.97871Z" />
            </svg>
        )}
    </ThemeConsumer>
);

const Container = styled(Button)`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    width: auto;
    top: 1rem;
    right: 1rem;
    left: 1rem;
    margin: 0 auto;
    padding: 0.5rem 1rem 0.75rem;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 3;
`;

const Label = styled(Text)`
    margin-bottom: 0.25rem;
    text-transform: uppercase;

    ${breakpoints.smallLandscape} {
        font-size: 10px;
    }
`;

export default function CTA({label, ...otherProps}) {
    return (
        <Container href={{pathname: paths.summary()}} {...otherProps}>
            <Label type={Text.types.tinyBold}>
                {label || 'FIND SONGS FOR EVERY MOMENT WITH'}
            </Label>
            <Logo />
        </Container>
    );
}
