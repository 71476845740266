import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {shade} from 'polished';

import emojis from 'config/emojis';
import animations from 'config/animations';
import * as queryconfig from 'config/queryparams';
import * as trackSelectors from 'selectors/track';
import * as breakpoints from 'theme/breakpoints';

import {
    Animation,
    CTA,
    Drawer,
    Emoji,
    Fizzer,
    Footer,
    Page,
    Panel,
    Player,
} from 'components';

import {paths, TrackContext} from 'App';

import Next from './Next/Next';

import {track} from 'util/analytics';
import {useQueryParams} from 'util/url';
import {getSurpriseEmoji} from 'selectors/emoji';
import {getTrack, searchEmojis, searchTracks} from 'api';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const Container = styled(Page)`
    padding: 0;
    background: ${(props) => shade(0.25, props.theme.background)};

    ${breakpoints.landscape} {
        flex-direction: row;
        align-items: stretch;
    }
`;

const TopPanel = styled(Panel)`
    position: relative;
    ${breakpoints.landscape} {
        flex: 0 0 60%;
    }
`;

const BottomPanel = styled(Drawer)`
    padding: 0;
    min-height: 250px;
    position: relative;
    transition: min-height 0.5s ease-in-out;

    ${(props) =>
        props.$isPreview ||
        css`
            min-height: 300px;

            ${breakpoints.smallPhone} {
                min-height: 260px;
            }
        `}

    ${breakpoints.portrait} {
        width: 100%;
    }

    ${breakpoints.landscape} {
        width: 40%;
    }
`;

const Scene = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
`;

const Character = styled(Animation)`
    width: 100%;
`;

const EmojiFizzer = styled(Fizzer)`
    position: absolute;

    min-width: 320px;
    min-height: 320px;

    max-width: 480px;
    max-height: 480px;

    ${breakpoints.portrait} {
        width: 45vw;
        height: 45vw;
    }

    ${breakpoints.landscape} {
        width: 45vh;
        height: 45vh;
    }
`;

const Emojis = styled.div`
    display: flex;
    position: absolute;
    top: 75%;

    > * {
        margin: 0.5rem;
    }
`;

const FloatingCTA = styled(CTA)`
    ${breakpoints.landscape} {
        ${(props) =>
            props.$portrait &&
            css`
                display: none;
            `}
    }

    ${breakpoints.portrait} {
        ${(props) =>
            props.$landscape &&
            css`
                display: none;
            `}
    }
`;

export default function Track({
    emojiSelections,
    onEmojiSelect,
    onPreviewComplete,
    onShuffle,
    disableShuffle,
}) {
    const promise = useRef(null);
    const location = useLocation();
    const history = useHistory();

    const isPreview = !useRouteMatch(paths.whatNext());
    const [isLastTrack, setIsLastTrack] = useState(false);

    // Same as App.js, we're just trying to find a unique value to trigger a transition.
    const transitionRoutes = useRouteMatch([
        paths.whatNext(),
        paths.playTrack(),
    ]);

    const {trackId} = useParams();
    const {search} = useLocation();

    const queryParams = useQueryParams();
    const age = queryParams.get(queryconfig.age);

    const firstEmoji = emojiSelections[0];
    const currentEmoji = emojis[emojiSelections[emojiSelections.length - 1]];

    const [emojiOptions, setEmojiOptions] = useState();

    const initEmojiOptions = useCallback(
        (emojiId, trackId) => {
            searchEmojis(emojiId, trackId).then(({related}) => {
                const emojis = related.slice(0, 2);
                if (Math.random() < 1 / 3) emojis[1] = getSurpriseEmoji(age).id;
                setEmojiOptions(emojis);
            });
        },
        [age]
    );

    const handlePreviewComplete = useCallback(() => {
        onPreviewComplete(trackId);
    }, [onPreviewComplete, trackId]);

    const handleOnShuffle = useCallback(() => {
        onShuffle(trackId);
    }, [onShuffle, trackId]);

    useEffect(() => {
        if (
            !emojiOptions &&
            emojiSelections.length === 1 &&
            trackId &&
            trackId !== 'track'
        ) {
            initEmojiOptions(firstEmoji, trackId);
        }
    }, [
        emojiOptions,
        emojiSelections.length,
        firstEmoji,
        initEmojiOptions,
        trackId,
    ]);

    useEffect(() => {
        if (!trackId || trackId === 'track') return;
        const trackingVars = isPreview ? {track: trackId} : undefined;
        track(`Page: ${isPreview ? 'Play Track' : 'What Next?'}`, trackingVars);
    }, [isPreview, trackId]);

    return (
        <TrackContext.Consumer>
            {({track, setTrack}) => {
                if (trackId !== trackSelectors.getId(track)) {
                    track = null;

                    if (!promise.current) {
                        if (trackId === 'track') {
                            promise.current = searchTracks(
                                emojiSelections,
                                age
                            ).then(({tracks}) => {
                                let tracksPlayed = queryParams.getAll(
                                    queryconfig.track
                                );
                                const track = trackSelectors.getTopTrack(
                                    tracks,
                                    tracksPlayed
                                );
                                if (track) {
                                    history.replace({
                                        pathname: paths.playTrack(track.id),
                                        search,
                                    });
                                    setTrack(track);
                                    promise.current = null;
                                    tracksPlayed.push(track.id);
                                    const nextTrack = trackSelectors.getTopTrack(
                                        tracks,
                                        tracksPlayed
                                    );
                                    if (!nextTrack) setIsLastTrack(true);
                                } else {
                                    handlePreviewComplete();
                                }
                            });
                        } else {
                            promise.current = getTrack(trackId).then(
                                (response) => {
                                    setTrack(response);
                                    promise.current = null;
                                }
                            );
                        }
                    }
                }

                return (
                    <Container trackingLabel={null}>
                        <TopPanel>
                            <FloatingCTA $portrait />
                            {track && (
                                <Scene>
                                    <Character
                                        animation={
                                            animations[currentEmoji.character]
                                        }
                                    />
                                    <EmojiFizzer emoji={currentEmoji.id} />
                                </Scene>
                            )}
                            <Emojis>
                                {emojiSelections.map((emoji, index) => (
                                    <Emoji
                                        elevated
                                        emoji={emoji}
                                        key={emoji + index}
                                    />
                                ))}
                            </Emojis>
                        </TopPanel>
                        <BottomPanel $isPreview={isPreview}>
                            <FloatingCTA $landscape />
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={transitionRoutes.path}
                                    timeout={500}
                                >
                                    <Switch location={location}>
                                        <Route path={paths.whatNext()}>
                                            <Next
                                                emojiOptions={emojiOptions}
                                                onEmojiSelect={onEmojiSelect}
                                            />
                                        </Route>
                                        <Player
                                            onPreviewComplete={
                                                handlePreviewComplete
                                            }
                                            onShuffle={handleOnShuffle}
                                            track={track}
                                            disableShuffle={
                                                isLastTrack || disableShuffle
                                            }
                                        />
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        </BottomPanel>
                        <Footer />
                    </Container>
                );
            }}
        </TrackContext.Consumer>
    );
}
