export default {
    angry: {
        keyframes: [1, 120, 240, 334],
        src: '/static/animations/angry.json',
    },
    boogie1: {
        keyframes: [1, 168, 336, 475],
        src: '/static/animations/boogie1.json',
    },
    boogie2: {
        keyframes: [1, 120, 240, 310],
        src: '/static/animations/boogie2.json',
    },
    cool: {
        keyframes: [1, 132, 264, 390],
        src: '/static/animations/cool.json',
    },
    halo: {
        keyframes: [1, 118, 236, 258],
        src: '/static/animations/halo.json',
    },
    happy: {
        keyframes: [1, 98, 223, 246],
        src: '/static/animations/happy.json',
    },
    hearteyes: {
        keyframes: [1, 121, 242, 262],
        src: '/static/animations/hearteyes.json',
    },
    home: {
        src: '/static/animations/home.json',
    },
    kiss: {
        keyframes: [1, 107, 214, 241],
        src: '/static/animations/kiss.json',
    },
    laughing: {
        keyframes: [1, 168, 336, 483],
        src: '/static/animations/laughing.json',
    },
    party: {
        keyframes: [1, 124, 248, 368],
        src: '/static/animations/party.json',
    },
    shrug: {
        src: '/static/animations/shrug.json',
    },
    shuffle: {
        src: '/static/animations/shuffle.json',
    },
    silly: {
        keyframes: [1, 89, 178, 209],
        src: '/static/animations/silly.json',
    },
    sleepy: {
        keyframes: [1, 65, 216, 311],
        src: '/static/animations/sleepy.json',
    },
    stareyes: {
        keyframes: [1, 92, 184, 221],
        src: '/static/animations/stareyes.json',
    },
    upset: {
        keyframes: [1, 107, 214, 321],
        src: '/static/animations/upset.json',
    },
};

export const summaryAnimations = {
    app: {
        keyframes: [1, 75, 325, 325],
        src: '/static/animations/summary/app/app.json',
    },
    junior: {
        src: '/static/animations/summary/junior.json',
    },
    jesse: {
        src: '/static/animations/summary/jesse.json',
    },
    goldie: {
        src: '/static/animations/summary/goldie.json',
    },
    group1: {
        src: '/static/animations/summary/group1.json',
    },
};
