export const white = '#FFFFFF';
export const grey95 = '#F8F8F8';
export const grey90 = '#EEEEEE';
export const grey75 = '#BDBDBD';
export const grey70 = '#B3B3B3';
export const grey60 = '#9E9E9E';
export const grey45 = '#757575';
export const grey30 = '#535353';
export const grey25 = '#404040';
export const grey20 = '#333333';
export const grey15 = '#282828';
export const grey10 = '#181818';
export const grey07 = '#121212';
export const black = '#000000';

export const green = '#19E68C';
export const orange = '#F59B23';
export const yellow = '#FAE62D';

export const themes = {
    blue: {
        primary: '#2D46B9',
        highlight: '#6D91E3',
        secondary: '#241E6C',
        background: '#101C3B',
        accent: orange,
    },
    purple: {
        primary: '#773795',
        highlight: '#D390EA',
        secondary: '#492056',
        background: '#341B46',
        accent: green,
    },
    violet: {
        primary: '#AF2896',
        highlight: '#FF93EB',
        secondary: '#7B1E69',
        background: '#46153D',
        accent: yellow,
    },
};
