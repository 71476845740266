import React, {useCallback, useEffect, useRef} from 'react';
import lottie from 'lottie-web/build/player/lottie_light';
import styled from 'styled-components';

import {getIntroSegments} from 'selectors/animation';

const Container = styled.div`
    pointer-events: none;
`;

export default function Animation({
    animation,
    delay = 500,
    scale = 'xMidYMid slice',
    ...otherProps
}) {
    const ref = useRef();
    const animationRef = useRef();

    const playAnimation = useCallback(() => {
        const segments = getIntroSegments(animation);
        segments
            ? animationRef.current.playSegments(segments, true)
            : animationRef.current.play();
    }, [animation]);

    const initAnimation = useCallback(
        animation => {
            animationRef.current = lottie.loadAnimation({
                autoplay: false,
                container: ref.current,
                path: animation.src,
                quality: 'low',
                renderer: 'svg',
                rendererSettings: {
                    preserveAspectRatio: scale,
                },
            });

            animationRef.current.setSubframe(false); // Slow animation to 30fps to help performance.

            animationRef.current.addEventListener('DOMLoaded', playAnimation);
        },
        [animationRef, playAnimation, scale]
    );

    const destroyAnimation = useCallback(() => {
        animationRef.current && animationRef.current.destroy();
    }, [animationRef]);

    useEffect(() => {
        destroyAnimation();
        if (!animation) return;

        delay
            ? setTimeout(() => initAnimation(animation), delay)
            : initAnimation(animation);

        const element = ref.current;

        return () => {
            // On unmount...
            lottie.destroy(element);
        };
    }, [delay, destroyAnimation, initAnimation, animation]);

    return <Container aria-hidden ref={ref} {...otherProps} />;
}
