import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import Text from 'components/Text/Text'; // Strange bug required importing this directly...
import {Button} from 'components';

import * as colors from 'theme/colors';
import * as breakpoints from 'theme/breakpoints';
import * as transitions from 'theme/transitions';
import * as trackSelectors from 'selectors/track';
import {ease, pageTransitions} from 'theme/transitions';

import {AudioContext} from 'App';
import MuteButton from './MuteButton/MuteButton';
import ShuffleButton from './ShuffleButton/ShuffleButton';
import Modals from 'modals/Modals';

const MUTED_KEY = 'muted';

const PREVIEW_DURATION = 8;
const VOLUME_LOW = 0.2;

const Container = styled.div`
    display: flex;
    position: static !important; // Don't override with page transitions.
    width: 100%;
    height: 100%;

    padding: 0 2rem 3rem 2rem;
    align-items: center;
    justify-content: space-around;
    color: ${colors.white};
    ${pageTransitions}

    ${breakpoints.portrait} {
        max-width: 720px;
    }

    ${breakpoints.landscape} {
        flex-direction: column;
    }

    ${breakpoints.smallLandscape} {
        padding-bottom: 1.5rem;
    }
`;

const Info = styled(Button)`
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    padding: 0 0.5rem 0.25rem;
    border-radius: 0.5rem;
    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 0s both;
    ${breakpoints.smallLandscape} {
        padding-bottom: 0.05rem;
    }
`;

const Artwork = styled.div`
    width: 72px;
    height: 72px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4.5px;
    background: ${(props) => props.theme.secondary} no-repeat center;
    background-size: cover;
    transform: rotate(-2deg);

    ${breakpoints.landscape} {
        width: 96px;
        height: 96px;
    }

    ${breakpoints.smallLandscape} {
        width: 70px;
        height: 70px;
    }

    ${breakpoints.small} {
        width: 96px;
        height: 96px;
    }

    ${breakpoints.medium} {
        width: 128px;
        height: 128px;
    }
`;

const Heading = styled(Text)`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${breakpoints.smallLandscape} {
        font-size: 14px;
    }
`;

const Subheading = styled(Text)`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.7);

    ${breakpoints.smallLandscape} {
        font-size: 11px;
    }
`;

const SidePanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 20%;
`;

const Progress = styled.div`
    position: absolute;
    bottom: 0;
    background: ${colors.yellow};

    ${breakpoints.portrait} {
        height: 0.5rem !important;
        left: 0;
    }

    ${breakpoints.landscape} {
        width: 0.5rem !important;
        right: 0;
    }
`;

const getPercentRemaining = (currentTime) =>
    `${100 - (currentTime / PREVIEW_DURATION) * 100}%`;

function Player({audio, onPreviewComplete, track, onShuffle, disableShuffle}) {
    const progressRef = useRef(null);

    const audioSrc = trackSelectors.getAudioSrc(track);
    const imageSrc = trackSelectors.getImageSrc(track);
    const artist = trackSelectors.getArtist(track);
    const title = trackSelectors.getTitle(track);

    const [time, setTime] = useState(0);

    const [muted, setMuted] = useState(
        localStorage.getItem(MUTED_KEY) === 'true'
    );

    const handleTimeUpdate = useCallback(
        ({currentTarget: {currentTime}}) => setTime(currentTime),
        [setTime]
    );

    const addListeners = useCallback(() => {
        audio.addEventListener('pause', handleTimeUpdate);
        audio.addEventListener('timeupdate', handleTimeUpdate);
    }, [audio, handleTimeUpdate]);

    const removeListeners = useCallback(() => {
        audio.removeEventListener('pause', handleTimeUpdate);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
    }, [audio, handleTimeUpdate]);

    const handlePreviewComplete = useCallback(() => {
        removeListeners();
        onPreviewComplete();
    }, [onPreviewComplete, removeListeners]);

    const handleMuteClicked = useCallback(() => {
        setMuted(!muted);
    }, [muted, setMuted]);

    const handleMount = useCallback(() => {
        gsap.to(audio, {volume: 1});
    }, [audio]);

    const handleUnmount = useCallback(() => {
        removeListeners();
        gsap.to(audio, {volume: VOLUME_LOW});
        gsap.killTweensOf(progressRef.current);
    }, [audio, removeListeners]);

    useEffect(() => {
        handleMount();
        return handleUnmount;
    }, [handleMount, handleUnmount]);

    useEffect(() => {
        // When source changes...
        audio.autoplay = true;
        audio.src = audioSrc;

        if (audioSrc) {
            addListeners();
            audio.play();
        }
    }, [addListeners, audio, audioSrc]);

    useEffect(() => {
        // When time changes...
        gsap.killTweensOf(progressRef.current);
        const percent = getPercentRemaining(time);
        if (time === 0) {
            gsap.set(progressRef.current, {height: percent, width: percent});
        } else if (time >= PREVIEW_DURATION) {
            handlePreviewComplete();
        } else {
            gsap.fromTo(
                progressRef.current,
                {height: percent, width: percent},
                {
                    duration: PREVIEW_DURATION - time,
                    ease: 'none',
                    height: 0,
                    width: 0,
                }
            );
        }
    }, [handlePreviewComplete, time]);

    useEffect(() => {
        // When mute changes...
        localStorage.setItem(MUTED_KEY, String(muted));
        audio.muted = muted;
    }, [audio, muted]);

    return (
        <Container>
            <Progress ref={progressRef} />
            <SidePanel>
                <MuteButton
                    muted={muted}
                    onClick={handleMuteClicked}
                    trackingLabel="Mute"
                    trackingVars={{mute: !muted}}
                    onlyMobile
                />
            </SidePanel>
            <Info href={Modals.getOpenUrl(Modals.ids.trackinfo)} replace>
                <Artwork style={{backgroundImage: `url(${imageSrc})`}} />
                <Heading type={Text.types.bodyBold}>
                    {title || <>&nbsp;</>}
                </Heading>
                <Subheading type={Text.types.small}>
                    {artist || <>&nbsp;</>}
                </Subheading>
            </Info>
            <SidePanel>
                <MuteButton
                    muted={muted}
                    onClick={handleMuteClicked}
                    trackingLabel="Mute"
                    trackingVars={{mute: !muted}}
                />
                <ShuffleButton onClick={onShuffle} disabled={disableShuffle} />
            </SidePanel>
        </Container>
    );
}

export default ({...otherProps}) => (
    <AudioContext.Consumer>
        {(audioRef) =>
            audioRef.current && (
                <Player audio={audioRef.current} {...otherProps} />
            )
        }
    </AudioContext.Consumer>
);
