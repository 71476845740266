import React, {useEffect, useState} from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {paths} from 'App';
import * as queryconfig from 'config/queryparams';
import {useQueryParams} from 'util/url';

import Home from './Home/Home';
import SelectAge from './SelectAge/SelectAge';
import SelectEmoji from './SelectEmoji/SelectEmoji';
import Summary from './Summary/Summary';

function shouldRedirect(pathname, debug = null) {
    // Don't redirect to home if debug queryparam is set, or this is development...
    if (debug !== null || process.env.NODE_ENV === 'development') {
        return false;
    }

    // Don't redirect needlessly on home or age (to allow AB testing of landing)
    if (pathname === paths.home() || pathname === paths.selectAge()) {
        return false;
    }

    return true;
}

export default function Pages() {
    const location = useLocation();
    const params = useQueryParams();
    const debug = params.get(queryconfig.debug);

    const [redirectToLanding, setRedirectToLanding] = useState(
        shouldRedirect(location.pathname, debug)
    );

    // This is a touch confusing, but the point is basically to transition some pages and not others.
    // The value of transitionRoutes.path will change only for the specified routes.
    // The order is important because we're not using exact matching.
    const transitionRoutes = useRouteMatch([
        paths.selectAge(),
        paths.playTrack(),
        paths.selectEmoji(),
        paths.mixItUp(),
        paths.summary(),
        paths.home(),
    ]);

    // Scroll to top on route change...
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 250);
    }, [location.pathname]);

    // Allow app to run once redirect has occurred...
    useEffect(() => {
        setRedirectToLanding(false);
    }, []);

    // Reset the experience for new visitors (and on refresh!) with params
    return redirectToLanding ? (
        <Redirect
            to={`${paths.home()}${
                params.toString() ? `?${params.toString()}` : ''
            }`}
        />
    ) : (
        <React.Fragment>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={transitionRoutes.path} // Only transitions if this is unique.
                    timeout={500}
                >
                    <Switch location={location}>
                        <Route path={paths.selectAge()}>
                            <SelectAge />
                        </Route>
                        <Route path={[paths.selectEmoji(), paths.mixItUp()]}>
                            <SelectEmoji />
                        </Route>
                        <Route path={paths.summary()}>
                            <Summary />
                        </Route>
                        <Home />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </React.Fragment>
    );
}
