export const get = url =>
    new Promise((resolve, reject) =>
        fetch(url).then(response =>
            response.status === 200
                ? resolve(response.json())
                : reject(response)
        )
    );

export const getTrack = id => get(`/api/tracks/${id}`);
export const getPlaylist = id => get(`/api/playlist/${id}`);

export const searchEmojis = (emojiId, trackId) => {
    const params = new URLSearchParams();

    params.append('emoji', emojiId);
    params.append('track', trackId);

    return get(`/api/emoji/related?${params.toString()}`);
};

export const searchTracks = (emojiIds, age) => {
    const params = new URLSearchParams();

    if (age) params.append('age', age);

    Array.isArray(emojiIds) &&
        emojiIds.forEach(emojiId => params.append('emoji', emojiId));

    return get(`/api/tracks/search?${params.toString()}`);
};

export const searchPlaylists = (trackIds, quantity) => {
    const params = new URLSearchParams();
    quantity && params.append('number', quantity);

    Array.isArray(trackIds) &&
        trackIds.forEach(trackId => params.append('track', trackId));

    return get(`/api/playlist/search?${params.toString()}`);
};
