import React from 'react';
import styled from 'styled-components';

import * as breakpoints from 'theme/breakpoints';

import phone from 'images/phone.svg';

// Use em not px to keep things scalable!

const Container = styled.div`
    position: relative;
    width: 326.96em;
    height: 413.45em;
    font-size: 0.75px;

    ${breakpoints.tablet} {
        font-size: 1px;
    }
`;

const Screen = styled.div`
    position: absolute;
    top: 24em;
    left: 64em;
    width: 200em;
    height: 320em;
    background: black;
    overflow: hidden;
`;

const Frame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${phone}) no-repeat center;
    background-size: contain;
`;

export default function Phone({children, ...otherProps}) {
    return (
        <Container {...otherProps}>
            <Screen>{children}</Screen>
            <Frame />
        </Container>
    );
}
