import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import FocusTrap from 'focus-trap-react';
import {useHistory} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';

import * as colors from 'theme/colors';
import closeIcon from 'images/icons/close.svg';

import {Button} from 'components';
import {getClosedUrl} from 'modals/Modals';
import {pageTransitions} from 'theme/transitions';
import * as breakpoints from 'theme/breakpoints';
import {track} from '../../util/analytics';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    overflow: hidden;
  }
  
  #modal-root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
`;

const Container = styled.aside`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 1rem;
    ${pageTransitions}
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
`;

const Panel = styled.div`
    position: relative;
    width: 100%;
    padding: 2rem;
    min-height: 4.25rem;
    max-width: 360px;
    border-radius: 20px;
    color: ${colors.white};
    background: ${(props) => props.theme.secondary};
    text-align: center;
    overflow-x: hidden;

    ${breakpoints.smallPhone} {
        padding: 1.5rem;
    }
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.5) url(${closeIcon}) no-repeat center;
    border-radius: 1000px;
    border: none;
`;

export default function Modal({children, trackingLabel}) {
    const history = useHistory();
    const closedUrl = getClosedUrl();

    const handleClose = () => {
        history.replace(closedUrl);
        trackingLabel && track(`Button: Close Modal`);
    };

    useEffect(() => {
        trackingLabel && track(`Modal: ${trackingLabel}`);
    }, [trackingLabel]);

    return createPortal(
        <FocusTrap>
            <Container aria-modal="true" role="dialog">
                <GlobalStyle />
                <Background onClick={handleClose} />
                <Panel>
                    <CloseButton
                        aria-label="Close"
                        href={closedUrl}
                        round
                        replace
                        trackingLabel={`Close Modal`}
                    />
                    {children}
                </Panel>
            </Container>
        </FocusTrap>,
        document.getElementById('modal-root')
    );
}
