import React, {useEffect} from 'react';
import styled from 'styled-components';
import {pageTransitions} from 'theme/transitions';
import {track} from 'util/analytics';

const Container = styled.main`
    display: flex;
    padding: 2rem 2rem 1rem 2rem;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
        ${(props) => props.theme.secondary} 0%,
        ${(props) => props.theme.background} 100%
    );

    ${pageTransitions}
`;

export default function Page({children, trackingLabel, ...otherProps}) {
    useEffect(() => {
        trackingLabel && track(`Page: ${trackingLabel}`);
    }, [trackingLabel]);

    return <Container {...otherProps}>{children}</Container>;
}
