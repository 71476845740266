import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text/Text';
import DownloadButton from 'components/Button/Download/DownloadButton';
import {Modal} from 'components';

import * as trackSelectors from 'selectors/track';

import kids from 'images/logos/kids.svg';
import placeholder from './images/placeholder.png';

import {TrackContext} from 'App';
import * as breakpoints from 'theme/breakpoints';

const Logo = styled.img`
    display: block;
    margin: 1rem auto 2rem;
    width: 117px;

    ${breakpoints.smallPhone} {
        margin: 0.5rem auto 1rem;
    }
`;

const Heading = styled(Text)`
    display: block;
    margin-bottom: 2rem;

    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const Placeholder = styled.div`
    margin: 0 -2rem 2rem;

    &:before {
        content: '';
        display: block;
        padding-top: 34.783%;
        background: url(${placeholder}) no-repeat center;
        background-size: cover;
    }

    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const Artwork = styled.div`
    width: 118px;
    height: 118px;
    margin: 0 auto 2rem;
    border-radius: 8px;
    background: ${(props) => props.theme.primary} no-repeat center;
    background-size: cover;
    transform: rotate(-2deg);

    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const Description = styled(Text)`
    display: block;
    margin-bottom: 2rem;

    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const Download = styled(DownloadButton)`
    margin: 0 auto;
`;

export default function TrackInfo({onClose}) {
    return (
        <TrackContext.Consumer>
            {({track}) => {
                const image = trackSelectors.getImageSrc(track);

                return (
                    <Modal onClose={onClose} trackingLabel="Track Info">
                        <Logo alt="Spotify Kids" src={kids} />
                        <Heading tag="h1" type={Text.types.h3}>
                            Their first steps in music start here.
                        </Heading>
                        {image ? (
                            <Artwork
                                style={{backgroundImage: `url(${image})`}}
                            />
                        ) : (
                            <Placeholder />
                        )}
                        <Description tag="p" type={Text.types.body}>
                            Let the discovery begin in the Spotify Kids app,
                            exclusively available with a Spotify Premium Family
                            subscription.
                        </Description>
                        <Download trackingLabel="Track Info: Download Spotify Kids App" />
                    </Modal>
                );
            }}
        </TrackContext.Consumer>
    );
}
