export const breakpoint = (minWidth) =>
    `@media screen and (min-width:${minWidth}px)`;

export const portrait = `@media screen and (orientation: portrait)`;
export const landscape = `@media screen and (orientation: landscape)`;
export const smallLandscape = `@media screen and (orientation: landscape) and (max-height: 480px)`;

export const phablet = breakpoint(480);
export const smallPhone = `@media screen and (max-height: 736px)`;
export const tablet = breakpoint(768);
export const small = breakpoint(1024);
export const medium = breakpoint(1280);
export const large = breakpoint(1600);
