import React from 'react';
import styled from 'styled-components';

import shuffleOn from 'images/icons/shuffle.svg';
import shuffleOff from 'images/icons/shuffle-off.svg';

import {Button} from 'components';

const StyledButton = styled(Button)`
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    background-image: url(${(props) =>
        props.disabled ? shuffleOff : shuffleOn});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    border: none;
`;

export default function ShuffleButton({disabled, onClick, ...otherProps}) {
    return (
        <StyledButton
            aria-label={disabled ? 'Disabled shuffle' : 'Shuffle'}
            disabled={disabled}
            onClick={() => {
                !disabled && onClick();
            }}
            round
            trackingLabel="Shuffle: click"
            {...otherProps}
        />
    );
}
