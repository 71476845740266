import React, {useCallback, useEffect, useRef, useState} from 'react';
import twemoji from 'twemoji';
import gsap from 'gsap';

import emojis from 'config/emojis';

export default function Fizzer({emoji, qty = 7, ...otherProps}) {
    const ref = useRef();
    const particle = useRef();
    const particles = useRef();

    const [image, setImage] = useState();

    const canvas = ref.current;
    const context = canvas?.getContext('2d');

    const update = useCallback(() => {
        if (!context || !image) return;
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        for (let i = 0; i < particles.current.length; i++) {
            particle.current = particles.current[i];

            context.globalAlpha = particle.current.opacity;
            context.drawImage(
                image,
                particle.current.x - particle.current.size / 2,
                particle.current.y - particle.current.size / 2,
                particle.current.size,
                particle.current.size
            );
        }
    }, [context, image]);

    const initAnimation = useCallback(() => {
        particles.current = [];
        let timeline;

        for (let i = 0; i < qty; i++) {
            particle.current = {
                opacity: 1,
                size: 50,
                x: (i / qty) * 450 + 50,
                y: 50,
            };

            // Create timeline...
            timeline = gsap.timeline({
                delay: Math.random() + 1,
                onUpdate: update,
                repeat: -1,
            });

            // Add fade in effect...
            timeline.from(
                particle.current,
                {
                    duration: 0.2,
                    opacity: 0,
                    roundProps: 'size',
                    size: 0,
                },
                0
            );

            // Add wiggle effect...
            timeline.to(
                particle.current,
                {
                    duration: 0.25,
                    ease: 'power1.inOut',
                    repeat: 4,
                    roundProps: 'x',
                    x: '+=random(-16, 16)',
                    yoyo: true,
                },
                0
            );

            // Add rising effect...
            timeline.from(
                particle.current,
                {
                    duration: 1,
                    ease: 'power1.in',
                    onUpdate: update,
                    roundProps: 'y',
                    y: 450,
                },
                0
            );

            // Add fade out effect...
            timeline.to(particle.current, {duration: 0.25, opacity: 0}, 0.75);

            timeline.timeScale(Math.random() * 0.5 + 0.5);

            particles.current.push(particle.current);
        }
    }, [qty, update]);

    const initEmoji = useCallback(id => {
        const emoji = emojis[id];
        if (!emoji) return;

        const div = document.createElement('div');
        div.innerHTML = twemoji.parse(emoji.symbol);

        const image = div.firstElementChild;
        image.addEventListener('load', () => setImage(image));
    }, []);

    useEffect(() => {
        initEmoji(emoji);
    }, [emoji, initEmoji]);

    useEffect(() => {
        initAnimation();
    }, [image, initAnimation]);

    return <canvas ref={ref} width={500} height={500} {...otherProps} />;
}
