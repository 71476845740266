export default {
    angry: {
        id: 'angry',
        character: 'angry',
        symbol: '😡',
        theme: 'blue',
        type: 'mood',
    },
    bath: {
        id: 'bath',
        character: 'sleepy',
        symbol: '🛁',
        theme: 'purple',
        type: 'action',
    },
    bedtime: {
        id: 'bedtime',
        character: 'sleepy',
        symbol: '🛏',
        theme: 'purple',
        type: 'action',
    },
    brokenheart: {
        id: 'brokenheart',
        character: 'upset',
        symbol: '💔',
        theme: 'violet',
        type: 'mood',
    },
    cool: {
        id: 'cool',
        character: 'cool',
        symbol: '😎',
        theme: 'violet',
        type: 'mood',
    },
    cowboy: {
        id: 'cowboy',
        age: 'older',
        character: 'cool',
        symbol: '🤠',
        theme: 'violet',
        type: 'surprise',
    },
    dance: {
        id: 'dance',
        character: 'boogie2',
        symbol: '🕺',
        theme: 'blue',
        type: 'action',
    },
    devil: {
        id: 'devil',
        character: 'angry',
        symbol: '😈',
        theme: 'blue',
        type: 'mood',
    },
    dinner: {
        id: 'dinner',
        character: 'sleepy',
        symbol: '🍽',
        theme: 'purple',
        type: 'action',
    },
    fire: {
        id: 'fire',
        character: 'cool',
        symbol: '🔥',
        theme: 'blue',
        type: 'action',
    },
    halo: {
        id: 'halo',
        character: 'halo',
        symbol: '😇',
        theme: 'purple',
        type: 'mood',
    },
    happy: {
        id: 'happy',
        character: 'happy',
        symbol: '🙂',
        theme: 'violet',
        type: 'mood',
    },
    hearteyes: {
        id: 'hearteyes',
        character: 'hearteyes',
        symbol: '😍',
        theme: 'blue',
        type: 'mood',
    },
    inlove: {
        id: 'inlove',
        character: 'kiss',
        symbol: '😘',
        theme: 'blue',
        type: 'mood',
    },
    monkey: {
        id: 'monkey',
        age: 'older',
        character: 'cool',
        symbol: '🐵',
        theme: 'violet',
        type: 'surprise',
    },
    music: {
        id: 'music',
        character: 'boogie2',
        symbol: '🎷',
        theme: 'blue',
        type: 'action',
    },
    party: {
        id: 'party',
        character: 'party',
        symbol: '🥳',
        theme: 'blue',
        type: 'mood',
    },
    paws: {
        id: 'paws',
        age: 'younger',
        character: 'boogie1',
        symbol: '🐶',
        theme: 'purple',
        type: 'surprise',
    },
    pig: {
        id: 'pig',
        age: 'younger',
        character: 'happy',
        symbol: '🐷',
        theme: 'violet',
        type: 'surprise',
    },
    poo: {
        id: 'poo',
        character: 'silly',
        symbol: '💩',
        theme: 'purple',
        type: 'action',
    },
    rainbow: {
        id: 'rainbow',
        character: 'boogie2',
        symbol: '🌈',
        theme: 'blue',
        type: 'action',
    },
    reading: {
        id: 'reading',
        character: 'sleepy',
        symbol: '📖',
        theme: 'purple',
        type: 'action',
    },
    robot: {
        id: 'robot',
        character: 'cool',
        symbol: '🤖',
        theme: 'violet',
        type: 'action',
    },
    rofl: {
        id: 'rofl',
        character: 'laughing',
        symbol: '😂',
        theme: 'purple',
        type: 'mood',
    },
    shark: {
        id: 'shark',
        age: 'younger',
        character: 'boogie1',
        symbol: '🦈',
        theme: 'purple',
        type: 'surprise',
    },
    silly: {
        id: 'silly',
        character: 'silly',
        symbol: '🤪',
        theme: 'purple',
        type: 'mood',
    },
    sing: {
        id: 'sing',
        character: 'boogie2',
        symbol: '🎤',
        theme: 'blue',
        type: 'action',
    },
    sleeping: {
        id: 'sleeping',
        character: 'sleepy',
        symbol: '😴',
        theme: 'blue',
        type: 'mood',
    },
    snowman: {
        id: 'snowman',
        character: 'boogie2',
        description: 'It just got cold',
        symbol: '⛄️',
        theme: 'blue',
        type: 'action',
    },
    sob: {
        id: 'sob',
        character: 'upset',
        symbol: '😭',
        theme: 'blue',
        type: 'mood',
    },
    sssh: {
        id: 'sssh',
        character: 'sleepy',
        symbol: '🤫',
        theme: 'blue',
        type: 'mood',
    },
    stareyes: {
        id: 'stareyes',
        character: 'stareyes',
        symbol: '🤩',
        theme: 'purple',
        type: 'mood',
    },
    tear: {
        id: 'tear',
        character: 'upset',
        symbol: '😢',
        theme: 'blue',
        type: 'mood',
    },
    thehorns: {
        id: 'thehorns',
        character: 'boogie2',
        symbol: '🤘',
        theme: 'blue',
        type: 'action',
    },
    tired: {
        id: 'tired',
        character: 'sleepy',
        symbol: '🥱',
        theme: 'blue',
        type: 'mood',
    },
    unicorn: {
        id: 'unicorn',
        character: 'stareyes',
        symbol: '🦄',
        theme: 'violet',
        type: 'action',
    },
};
