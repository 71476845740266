import React from 'react';
import styled from 'styled-components';

import {Button, Emoji, Floater, Text} from 'components';

import emojis from 'config/emojis';
import * as colors from 'theme/colors';
import * as transitions from 'theme/transitions';
import * as breakpoints from 'theme/breakpoints';

import {ease, pageTransitions} from 'theme/transitions';

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 2rem 4rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    ${pageTransitions}

    ${breakpoints.landscape} {
        padding-top: 6rem;
    }
`;

const Heading = styled(Text)`
    margin-bottom: 1rem;
    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 0.5s both;
`;

const Subheading = styled(Text)`
    margin-bottom: 2rem;
    animation: ${transitions.fadeSlideIn} 1s ${ease.sharp} 0.75s both;

    ${breakpoints.smallPhone} {
        margin-bottom: 1.5rem;
    }
`;

const Options = styled.div`
    display: flex;
    margin: -0.5rem;
`;

const Option = styled(Button)`
    margin: 0.5rem;
    animation: ${transitions.scaleIn} 1s ${ease.sharp} 1s both;
`;

export default function Next({emojiOptions, onEmojiSelect}) {
    const handleEmojiSelect = (emoji) => () => {
        onEmojiSelect(emoji);
    };

    return (
        <Container>
            <Heading tag="h1" type={Text.types.h4}>
                What next?
            </Heading>
            <Subheading tag="p" type={Text.types.body}>
                Pick a new emoji to change the music.
            </Subheading>
            <Options>
                {emojiOptions?.map((emoji, index) => (
                    <Floater key={emoji}>
                        <Option
                            aria-label={emojis[emoji].symbol}
                            elevated
                            onClick={handleEmojiSelect(emoji)}
                            round
                            style={{animationDelay: `${100 * index + 1000}ms`}}
                            trackingLabel="Emoji"
                            trackingVars={{emoji}}
                        >
                            <Emoji aria-hidden emoji={emoji} />
                        </Option>
                    </Floater>
                ))}
            </Options>
        </Container>
    );
}
