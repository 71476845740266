import React from 'react';
import styled from 'styled-components';

import * as typography from 'theme/typography';

const types = {
    h1: typography.watermelon,
    h3: typography.pineapple,
    h4: typography.mango,
    h5: typography.pomegranate,
    h6: typography.cranberry,
    body: typography.orangeBook,
    bodyBold: typography.orangeBold,
    small: typography.appleBook,
    smallBold: typography.appleBold,
    tiny: typography.blueberryBook,
    tinyBold: typography.blueberryBold,
};

const Component = styled.span`
    ${props => props.$type}
`;

function Text({tag, type, ...otherProps}) {
    return <Component as={tag} $type={type} {...otherProps} />;
}

Text.types = types;

export default Text;
