import React, {useCallback, useState} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {useQueryParams} from 'util/url';

import * as queryconfig from 'config/queryparams';

import Character from './Character/Character';
import Track from './Track/Track';

import {paths} from 'App';
import {themes} from 'theme/colors';
import emojis from 'config/emojis';

const MAX_ROUNDS = 2;
const EMOJIS_PER_ROUND = 2;

export default function SelectEmoji() {
    const [shuffledTracks, setShuffledTracks] = useState({});

    const history = useHistory();
    const {search} = useLocation();

    const queryParams = useQueryParams();
    const age = queryParams.get(queryconfig.age);

    const emojiSelections = queryParams.getAll(queryconfig.emoji);
    const lastEmoji = emojiSelections[emojiSelections.length - 1];
    const theme = themes[emojis[lastEmoji]?.theme] || themes.violet;

    // Get the emoji selections just for this round...
    const currentRound =
        Math.floor((emojiSelections.length - 1) / EMOJIS_PER_ROUND) + 1;
    const emojiSliceStart = (currentRound - 1) * EMOJIS_PER_ROUND;
    const emojiSliceEnd = emojiSliceStart + EMOJIS_PER_ROUND;
    const emojiSlice = emojiSelections.slice(emojiSliceStart, emojiSliceEnd);

    // When someone selects an emoji in the character or track component...
    const handleEmojiSelect = useCallback(
        (emoji) => {
            const params = new URLSearchParams(search);
            params.append(queryconfig.emoji, emoji);
            history.push({
                pathname: paths.playTrack('track'),
                search: params.toString(),
            });
        },
        [history, search]
    );

    // When a track preview finishes playing...
    const handlePreviewComplete = useCallback(
        (trackId) => {
            const params = new URLSearchParams(search);
            params.append(queryconfig.track, trackId);
            const queryString = params.toString();

            if (emojiSelections.length >= MAX_ROUNDS * EMOJIS_PER_ROUND) {
                history.push({pathname: paths.summary(), search: queryString});
            } else if (emojiSelections.length % EMOJIS_PER_ROUND === 0) {
                history.push({pathname: paths.mixItUp(), search: queryString});
            } else {
                history.push({
                    pathname: paths.whatNext(trackId),
                    search: queryString,
                });
            }
        },
        [emojiSelections.length, history, search]
    );

    // When user click shuffle...
    const handleShuffle = useCallback(
        (trackId) => {
            let newShuffledTracks = {...shuffledTracks};
            newShuffledTracks[lastEmoji] =
                (newShuffledTracks[lastEmoji] || 0) + 1;
            setShuffledTracks(newShuffledTracks);

            const params = new URLSearchParams(search);
            params.append(queryconfig.track, trackId);
            history.push({
                pathname: paths.playTrack('track'),
                search: params.toString(),
            });
        },
        [history, search, shuffledTracks, setShuffledTracks, lastEmoji]
    );

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route path={paths.playTrack()}>
                    <Track
                        age={age}
                        emojiSelections={emojiSlice}
                        onEmojiSelect={handleEmojiSelect}
                        onPreviewComplete={handlePreviewComplete}
                        onShuffle={handleShuffle}
                        disableShuffle={shuffledTracks[lastEmoji] === 3}
                    />
                </Route>
                <Character />
            </Switch>
        </ThemeProvider>
    );
}
