import React from 'react';
import styled from 'styled-components';
import {Animation, Button, CTA, Page, Text} from 'components';

import animations from 'config/animations';
import * as breakpoints from 'theme/breakpoints';
import * as transitions from 'theme/transitions';
import Footer from 'components/Footer/Footer';

import {paths} from 'App';

const Container = styled(Page)``;

const Character = styled(Animation)`
    width: 300px;
    height: 300px;
    margin: -4.5rem 0 -4rem;
    position: relative;
    z-index: 1;

    animation: ${transitions.fadeSlideIn} 1s ${transitions.ease.sharp} 0.5s both;

    ${breakpoints.smallPhone} {
        width: 250px;
        height: 250px;
    }
    ${breakpoints.tablet} {
        width: 600px;
        height: 600px;
        margin: -9rem 0 -8rem;
    }
`;

const Heading = styled(Text)`
    max-width: 420px;
    margin-bottom: 1rem;
    animation: ${transitions.fadeSlideIn} 1s ${transitions.ease.sharp} 1s both;
    ${breakpoints.smallPhone} {
        margin-bottom: 0.5rem;
    }
`;

const Description = styled(Text)`
    max-width: 320px;
    margin-bottom: 2rem;
    animation: ${transitions.fadeSlideIn} 1s ${transitions.ease.sharp} 1.5s both;
    ${breakpoints.smallPhone} {
        margin-bottom: 1rem;
    }
`;

const StartButton = styled(Button)`
    animation: ${transitions.fadeIn} 2s ${transitions.ease.sharp} 2s both;
`;

const SmallDescription = styled(Text)`
    max-width: 320px;
    margin-top: 2rem;
    animation: ${transitions.fadeSlideIn} 2s ${transitions.ease.sharp} 2s both;
    ${breakpoints.smallPhone} {
        margin-top: 1rem;
    }
`;

const Spacer = styled.div`
    flex: 1;
    ${breakpoints.smallLandscape} {
        display: none;
    }
`;

export default function Home() {
    return (
        <Container trackingLabel="Home">
            <CTA label="LEARN MORE ABOUT" />
            <Spacer />
            <Character animation={animations.home} />
            <Heading tag="h1" type={Text.types.h1}>
                Spotify Kids has music from playtime to bedtime.
            </Heading>
            <Description tag="p" type={Text.types.body}>
                Discover music from the app before you even download.
            </Description>
            <StartButton
                large
                href={paths.selectAge()}
                trackingLabel="Home: Let's go!"
            >
                Explore now
            </StartButton>
            <SmallDescription tag="p" type={Text.types.small}>
                Spotify Kids allows children to explore new sounds and find
                their favorite genres - and it’s included with a Premium Family
                Plan!
            </SmallDescription>
            <Spacer />
            <Footer isHome={true} />
        </Container>
    );
}
