import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {
    Animation,
    Button,
    DownloadButton,
    Footer,
    Page,
    Text,
} from 'components';
import Modals from 'modals/Modals';

import {summaryAnimations as animations} from 'config/animations';
import * as breakpoints from 'theme/breakpoints';
import * as typography from 'theme/typography';

import group2 from './images/group2.svg';
import robbie from './images/robbie.svg';
import fergus from './images/fergus.svg';
import simon from './images/simon.svg';
import tickIcon from 'images/icons/tick.svg';
import restartIcon from 'images/icons/refresh.svg';
import shareIcon from 'images/icons/share.svg';

import {paths} from 'App';
import {isAndroid, isIos} from 'util/device';

const Container = styled(Page)`
    position: relative !important;
    padding: 0;
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0 1rem;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    overflow: hidden;

    ${breakpoints.medium} {
        flex: 1 1 50%;
        min-height: 80vh;
        padding: 5rem 2rem;
        justify-content: center;
    }
`;

const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    flex: 1 1 50%;
    position: relative;
    padding: 1.5rem 1.5rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    ${breakpoints.medium} {
        padding: 5rem 2rem 11rem;
    }
`;

const RestartButton = styled(Button)`
    ${typography.raspberry}
    position: static;
    top: 1rem;
    width: auto;
    height: 36px;
    color: white;
    padding-left: 3rem;
    margin-bottom: 3rem;
    background: rgba(0, 0, 0, 0.5) url(${restartIcon}) no-repeat 10px center;
    border-radius: 1000px;
    border: none;
    line-height: 1;

    ${breakpoints.medium} {
        margin-bottom: 1rem;
    }
`;

const ShareButton = styled(Button)`
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.5) url(${shareIcon}) no-repeat 7px center;
    border-radius: 1000px;
    border: none;
    z-index: 1;
`;

const Heading1 = styled(Text)`
    max-width: 360px;
    margin-bottom: 1rem;
`;

const CTA1 = styled(DownloadButton)`
    margin-bottom: 1.25rem;
`;

const Disclaimer = styled(Text)`
    max-width: 280px;
    margin-bottom: 1.5rem;
    color: ${(props) => props.theme.highlight};
`;

const Phone = styled(Animation)`
    width: 100%;
    max-width: 500px;

    svg {
        display: block;
    }

    ${breakpoints.medium} {
        width: auto;
        max-width: none;
        height: 480px;
        margin-right: -200px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    ${breakpoints.large} {
        right: 10%;
    }
`;

const Heading2 = styled(Text)`
    max-width: 320px;
    margin-bottom: 0.5rem;
`;

const List = styled.ul`
    margin: 1rem 0;
    padding: 0;
    max-width: 280px;
    list-style: none;
    text-align: left;

    ${breakpoints.medium} {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 540px;
    }
`;

const ListItem = styled.li`
    margin-bottom: 1rem;
    padding-left: 2.5rem;
    background: url(${tickIcon}) no-repeat top left;
`;

const CTA2 = styled(DownloadButton)`
    margin-bottom: 1rem;
`;

const FindOutMore = styled(Button)`
    width: 100%;
    max-width: 300px;
    margin-bottom: 1.5rem;

    ${breakpoints.smallPhone} {
        font-size: 11px;
    }
`;

const Group2 = styled.div`
    width: 100%;
    height: 200px;
    margin-top: -3rem;
    background: url(${group2}) no-repeat bottom;
    background-size: contain;
    pointer-events: none;

    ${breakpoints.medium} {
        display: none;
    }
`;

const Robbie = styled.div`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        top: 5%;
        left: 0;
        width: 292px;
        height: 495px;
        background: url(${robbie}) no-repeat center;
        background-size: contain;
    }
`;

const Junior = styled(Animation)`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 640px;
        height: 320px;
        margin-bottom: -60px;
    }
`;

const Jesse = styled(Animation)`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        bottom: 0;
        width: 640px;
        margin-bottom: -290px;
    }
`;

const Goldie = styled(Animation)`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 640px;
        margin-bottom: -200px;
    }
`;

const Fergus = styled.div`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 376px;
        height: 338px;
        background: url(${fergus}) no-repeat center;
        background-size: contain;
    }
`;

const Simon = styled.div`
    display: none;

    ${breakpoints.medium} {
        display: block;
        position: absolute;
        left: 0;
        width: 383px;
        height: 634px;
        background: url(${simon}) no-repeat center;
        background-size: contain;
    }
`;

export default function Summary() {
    const history = useHistory();

    const handleShare = useCallback(() => {
        navigator.canShare?.()
            ? navigator.share()
            : history.replace(Modals.getOpenUrl(Modals.ids.share));
    }, [history]);

    return (
        <Container trackingLabel="Summary">
            <ShareButton aria-label="Share" round onClick={handleShare} />
            <TopContainer>
                <Robbie />
                <RestartButton
                    large
                    href={{pathname: paths.selectAge()}}
                    trackingLabel="Play Again"
                >
                    Play again
                </RestartButton>
                <Heading1 tag="h1" type={Text.types.h3}>
                    A music app designed for kids.
                </Heading1>
                <CTA1
                    trackingLabel={`Summary: ${
                        isAndroid || isIos ? 'Download App' : 'Find Out More'
                    }`}
                />
                <Disclaimer tag="small" type={Text.types.body}>
                    Exclusively available with a Spotify Premium Family
                    subscription
                </Disclaimer>
                <Phone animation={animations.app} delay={0} scale={null} />
                <Junior animation={animations.junior} delay={0} />
            </TopContainer>
            <BottomContainer>
                <Heading2 tag="h2" type={Text.types.h4}>
                    With Spotify Kids, your child can:
                </Heading2>
                <List>
                    <ListItem>
                        Discover kid-friendly music, audiobooks and more,
                        handpicked by our editors.
                    </ListItem>
                    <ListItem>
                        Enjoy content for every moment, from playtime to
                        bedtime.
                    </ListItem>
                    <ListItem>
                        Explore the app on their own or with you by their side.
                    </ListItem>
                </List>
                <CTA2
                    trackingLabel={`Summary: ${
                        isAndroid || isIos ? 'Download App' : 'Find Out More'
                    }`}
                />
                {(isAndroid || isIos) && (
                    <FindOutMore
                        small
                        dark
                        href="https://www.spotify.com/kids/"
                        trackingLabel="Summary: Find Out More"
                    >
                        Find out more about spotify kids
                    </FindOutMore>
                )}
                <Footer isSummary />
                <Group2 />
                <Fergus />
                <Jesse animation={animations.jesse} delay={0} />
                <Goldie animation={animations.goldie} delay={0} />
            </BottomContainer>
            <Simon />
        </Container>
    );
}
