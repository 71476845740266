export const track = (label, variables) => {
    if (label === undefined)
        throw new Error(
            "Tracking label is undefined. If you're sure you don't want to track this, pass null instead."
        );
    if (label)
        window.dataLayer.push({
            event: label,
            ...variables,
            debug_mode: process.env.NODE_ENV === 'development',
        });
};
