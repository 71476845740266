import emojis from 'config/emojis';
import {randomSlice} from 'util/array';

export const filterByAge = (emojis, age) =>
    age ? emojis.filter(emoji => emoji.age === age || !emoji.age) : emojis;

export const filterByType = (emojis, type) =>
    type ? emojis.filter(emoji => emoji.type === type) : emojis;

export const getEmojiOptions = age => {
    const emojisByAge = filterByAge(Object.values(emojis), age);

    const moodEmojis = randomSlice(filterByType(emojisByAge, 'mood'), 6);
    const actionEmojis = randomSlice(filterByType(emojisByAge, 'action'), 3);

    return randomSlice([...moodEmojis, ...actionEmojis]);
};

export const getSurpriseEmoji = age => {
    const emojisByAge = filterByAge(Object.values(emojis), age);
    const surpriseEmojis = filterByType(emojisByAge, 'surprise');
    return randomSlice(surpriseEmojis, 1)[0];
};
