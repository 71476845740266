import React from 'react';
import Twemoji from 'react-twemoji';
import styled, {css} from 'styled-components';

import emojis from 'config/emojis';

const Container = styled(Twemoji)`
    user-select: none;

    > img {
        width: 1em;
        height: 1em;
    }

    ${props =>
        props.$elevated &&
        css`
            display: flex;
            width: 1.25em;
            height: 1.25em;
            align-items: center;
            justify-content: center;
            border-radius: 1000px;
            background: ${props.theme.background};

            > img {
                width: 0.75em;
                height: 0.75em;
            }
        `}
`;

export default function Emoji({elevated, emoji, size = 36, ...otherProps}) {
    return (
        <Container
            $elevated={elevated}
            options={{
                ext: '.svg',
                folder: 'svg',
            }}
            style={{fontSize: size}}
            {...otherProps}
        >
            {emojis[emoji].symbol}
        </Container>
    );
}
