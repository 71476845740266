import React, {useRef, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';

import Pages from './pages/Pages';
import Modals from './modals/Modals';

import {themes} from './theme/colors';

export const AudioContext = React.createContext(null);

export const TrackContext = React.createContext({
    track: {},
    setTrack: () => {},
});

export const paths = {
    home: () => `/`,
    selectAge: () => `/age`,
    selectEmoji: () => `/play`,
    playTrack: (trackId = ':trackId') => `${paths.selectEmoji()}/${trackId}`,
    whatNext: (trackId) => `${paths.playTrack(trackId)}/what-next`,
    mixItUp: () => `/mix-it-up`,
    summary: () => `/summary`,
};

function App() {
    const audioRef = useRef(null);
    const [track, setTrack] = useState(null);

    return (
        <AudioContext.Provider value={audioRef}>
            <TrackContext.Provider value={{track, setTrack}}>
                <ThemeProvider theme={themes.violet}>
                    <BrowserRouter>
                        <Pages />
                        <Modals />
                    </BrowserRouter>
                </ThemeProvider>
                {track && <audio ref={audioRef} src={track.src} />}
            </TrackContext.Provider>
        </AudioContext.Provider>
    );
}
export default App;
