import {randomSlice} from 'util/array';

export const getId = track => track?.id;

export const getArtist = track => track?.artist;

export const getTitle = track => track?.title;

export const getImageSrc = track => track?.image;

export const getAudioSrc = track => track?.preview_url;

export const getEmojis = track => track?.emoji;

export const getTopTrack = (tracks, excludeTracks = []) =>
    randomSlice(
        tracks.filter(track => !excludeTracks.includes(track.id)).slice(0, 3),
        1
    )[0];
