import React from 'react';
import {useQueryParams} from 'util/url';

import Share from './Share/Share';
import TrackInfo from './TrackInfo/TrackInfo';

import * as queryconfig from 'config/queryparams';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

export const ids = {
    trackinfo: 'trackinfo',
    share: 'share',
};

export const getOpenUrl = modalId => {
    const {pathname, search} = new URL(window.location);
    const queryparams = new URLSearchParams(search);
    queryparams.set(queryconfig.modal, modalId);
    return `${pathname}?${queryparams}`;
};

export const getClosedUrl = () => {
    const {pathname, search} = new URL(window.location);
    const queryparams = new URLSearchParams(search);
    queryparams.delete(queryconfig.modal);
    return `${pathname}?${queryparams}`;
};

const getModal = modalId => {
    switch (modalId) {
        case ids.share:
            return <Share />;
        case ids.trackinfo:
            return <TrackInfo />;
        default:
            return null;
    }
};

function Modals() {
    const queryParams = useQueryParams();
    const modalId = queryParams.get(queryconfig.modal);
    const modal = getModal(modalId);

    return (
        <TransitionGroup>
            {modal ? (
                <CSSTransition key={modalId} timeout={500}>
                    {modal}
                </CSSTransition>
            ) : null}
        </TransitionGroup>
    );
}

Modals.getOpenUrl = getOpenUrl;
Modals.getClosedUrl = getClosedUrl;
Modals.ids = ids;

export default Modals;
